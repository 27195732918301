






















































































import Vue       from 'vue';

import { factory } from '@/logging';
import PageHeader from '@/components/products/pageheader.vue';
import PageFooter from '@/components/pagefooter.vue';

const logger = factory.getLogger('component.product.woodtype');

export default Vue.extend({
  name: 'woodTypes',
  props: {
    section: String
  },
  components: {
    PageHeader,
    PageFooter
  },
  created(): void { },
  methods:  { },
  computed: { },
  data() {
    return {
      navbar: 'products'
    };
  },
});
